<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{ $i18n.translate("Participant") }} - {{ $i18n.translate("Cancellations") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmitSearch">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClearSearch"
        ></v-text-field>
      </v-form>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        :server-items-length="total"
        :options.sync="options"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="ma-2" icon color="blue lighten-2" @click="onReject(item)">
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Confirm") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="ma-2" icon color="red lighten-2" @click="onApprove(item)">
                <v-icon>mdi-thumb-down</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Reject") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="approveDialog" max-width="600px" persistent>
        <v-card>
          <!-- Dialog Header -->
          <v-card-title class="headline">
            <span>Approve Registration</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <!-- Dialog Content -->
          <v-card-text>
            <div class="text-body-1">
              Are you sure you want to approve the registration request for
              <b>{{ itemSelected.fullName }}</b
              >?
            </div>
            <div class="mt-2 text-body-2">
              An email will be sent to <b>{{ itemSelected.email1 }}</b> with login instructions.
            </div>
          </v-card-text>

          <!-- Dialog Actions -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="close">
              {{ $i18n.translate("Cancel") }}
            </v-btn>
            <v-btn color="success" dark @click="approve" :loading="loading">
              Approve
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="rejectDialog" max-width="600px">
        <v-card>
          <!-- Dialog Header -->
          <v-card-title class="headline">
            <span>Reject Registration</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <!-- Dialog Content -->
          <v-card-text>
            <div class="text-body-1">
              Are you sure you want to reject the registration request for
              <b>{{ itemSelected.fullName }}</b
              >?
            </div>
            <div class="mt-2 text-body-2">
              An email will be sent to <b>{{ itemSelected.email1 }}</b> with the cancellation reason.
            </div>
          </v-card-text>

          <!-- Dialog Actions -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="close">
              {{ $i18n.translate("Cancel") }}
            </v-btn>
            <v-btn color="error" dark @click="reject" :loading="loading">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError },
  name: "Cancellations",
  metaInfo: {
    title: "Participant Cancellations"
  },

  data: function() {
    return {
      approveDialog: false,
      rejectDialog: false,
      rejectReason: undefined,
      rejectReasonValid: true,
      loading: false,
      search: "",
      headers: [],
      items: [],
      itemSelected: { user: {} },
      errors: {},
      total: 0,
      options: {
        itemsPerPage: 10
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    onApprove(item) {
      this.approveDialog = true;
      this.itemSelected = item;
    },

    approve() {
      this.loading = true;

      let patchForm = {};
      patchForm.status = { name: "ENR" };

      return this.$api
        .patch(`/api/participants/${this.itemSelected.id}`, patchForm)
        .then(() => {
          this.close();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.fetchData();
        });
    },

    onReject(item) {
      this.rejectDialog = true;
      this.itemSelected = item;
    },

    reject() {
      this.loading = true;

      let postForm = {};
      postForm.reason = this.itemSelected.cancelledReason;
      postForm.emailTemplateParticipantForm = { emailTemplateKey: "DEALERSHIP_CANCELLED" };

      return this.$api
        .post(`/api/participants/reject/${this.itemSelected.id}`, postForm)
        .then(() => {
          this.close();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.fetchData();
        });
    },

    onClearSearch() {
      this.search = "";
      this.fetchData();
    },
    onSubmitSearch() {
      this.options.page = 0;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      filters.status = { name: "PRE_CAN" };

      this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.approveDialog = false;
      this.rejectDialog = false;
      this.rejectReason = undefined;
      this.rejectReasonValid = true;
      this.search = "";
      this.errors = {};
    }
  },
  mounted() {
    this.headers = [
      { text: this.$i18n.translate("Participant") + " #", value: "participantKey" },
      { text: "First Name", value: "user.firstName" },
      { text: "Last Name", value: "user.lastName" },
      { text: "Email Address", value: "user.username" },
      { text: this.$i18n.translate("Participant Type"), value: "participantType.name" },
      { text: "Cancel Reason", value: "cancelledReason", align: "center" },
      { text: "Action", value: "action", sortable: false, align: "center" }
    ];
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Admin" },
      {
        text: "Participant Cancellations"
      }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
